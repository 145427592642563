<template>
  <div class="box">
    <!-- <div class="codeState" v-if="status == 99">
      <img src="../../assets/codeState/invalidActivate.jpg" alt="" />
    </div>
    <div v-else-if="status == 1"> -->
    <div class="codeState">
      <img
        v-if="status == 0"
        src="../../assets/codeState/orange-bg.png"
        alt=""
      />
      <img
        v-else-if="status == 1"
        src="../../assets/codeState/green-bg.png"
        alt=""
      />
      <img
        v-else-if="status == 2"
        src="../../assets/codeState/blue-bg.png"
        alt=""
      />
      <img v-else src="../../assets/codeState/gray-bg.png" alt="" />
      <div class="codeJudge">
        <p class="codeTitle">码状态</p>
        <div class="codeBorder" v-if="status == 0">
          <img
            src="https://actixpp20.66a1.cn/xcximages/xpp-state/icon-right.png"
            alt=""
          />
          正确数据
        </div>
        <div class="codeBorder" v-else-if="status == 1">
          <img
            src="https://actixpp20.66a1.cn/xcximages/xpp-state/icon-relation.png"
            alt=""
          />
          数据已关联
        </div>
        <div class="codeBorder" v-else-if="status == 2">
          <img
            src="https://actixpp20.66a1.cn/xcximages/xpp-state/icon-active.png"
            alt=""
          />
          数据已激活
        </div>
        <div class="codeBorder" v-else>
          <img
            src="https://actixpp20.66a1.cn/xcximages/xpp-state/icon-error.png"
            alt=""
          />
          错误数据
        </div>
      </div>
    </div>
    <ul class="codeDetails">
      <li>
        <p>客户品牌：</p>
        <span>{{ packet }}</span>
      </li>
      <li>
        <p>制盖工厂：</p>
        <span>{{ factory }}</span>
      </li>
      <li v-show="type == 0">
        <p>首码尾码：</p>
        <span>{{ sku }}</span>
      </li>
      <li>
        <p>赋码数量：</p>
        <span>{{ packageCreatTime }}</span>
      </li>
      <li>
        <p>箱或板号：</p>
        <span>{{ platecode }}</span>
      </li>
    </ul>
    <!-- </div> -->
    <div class="jszc">
      <img src="../../assets/jszc.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'codeState',
  data() {
    return {
      factory: '',
      sku: '',
      packageCreatTime: '',
      packet: '',
      status: '',
      platecode: '',
      type: 1
    }
  },
  methods: {},
  mounted: function () {
    this.factory = this.$route.query.factory
    this.sku = this.$route.query.sku
    this.packageCreatTime = this.$route.query.packageCreatTime
    this.packet = this.$route.query.packet
    this.status = this.$route.query.status
    this.platecode = this.$route.query.platecode
    this.type = this.$route.query.type
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.codeState {
  display: block;
  width: 100%;
  position: relative;
}
.codeState img {
  width: 100%;
}
.codeBorder {
  font-size: 36px;
  font-weight: bold;
  border-radius: 60px;
  border: 1px solid #fff;
  width: 400px;
  height: 80px;
  color: #fff;
  margin: 60px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 44px;
  }
}
body {
  background: rgba(0, 0, 0, 0);
}
.codeJudge {
  position: absolute;
  top: 20px;
  width: 100%;
}
.codeTitle {
  color: #fff;
  font-size: 28px;
  padding: 30px 0 0 30px;
}
.codeDetails {
  font-size: 26px;
  li {
    padding: 30px;
    display: flex;
    p {
      padding-right: 20px;
    }
    span {
      color: #666;
    }
  }
}
.jszc {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  img {
    width: 100%;
  }
}
</style>
